export const REQUEST_GET_ENTRIES = "REQUEST_GET_ENTRIES";
export const RECEIVE_GET_ENTRIES = "RECEIVE_GET_ENTRIES";
export const REQUEST_ADD_ENTRY = "REQUEST_ADD_ENTRY";
export const RECEIVE_ADD_ENTRY = "RECEIVE_ADD_ENTRY";
export const REQUEST_DELETE_ENTRY = "REQUEST_DELETE_ENTRY";
export const RECEIVE_DELETE_ENTRY = "RECEIVE_DELETE_ENTRY";
export const REQUEST_EDIT_ENTRY = "REQUEST_EDIT_ENTRY";
export const RECEIVE_EDIT_ENTRY = "RECEIVE_EDIT_ENTRY";
export const REQUEST_SIGN_IN = "REQUEST_SIGN_IN";
export const RECEIVE_SIGN_IN = "RECEIVE_SIGN_IN";
export const REQUEST_REGISTER_USER = "REQUEST_REGISTER_USER";
export const RECEIVE_REGISTER_USER = "RECEIVE_REGISTER_USER";
export const SELECT_DATE = "SELECT_DATE";
export const REQUEST_CLEAR_ENTRIES = "REQUEST_CLEAR_ENTRIES";
export const REQUEST_SECURE_ENTRY = "REQUEST_SECURE_ENTRY";
export const RECEIVE_SECURE_ENTRY = "RECEIVE_SECURE_ENTRY";
export const REQUEST_VERIFY_TOKEN = "REQUEST_VERIFY_TOKEN";
export const RECEIVE_VERIFY_TOKEN = "RECEIVE_VERIFY_TOKEN";
export const REQUEST_SEARCH_TERM = "REQUEST_SEARCH_TERM";
export const RECEIVE_SEARCH_TERM = "RECEIVE_SEARCH_TERM";
export const MODAL_HANDLE = "MODAL_HANDLE";
export const SIGN_OUT = "SIGN_OUT";
export const REQUEST_RECENT_ENTRIES = "REQUEST_RECENT_ENTRIES";
export const RECEIVE_RECENT_ENTRIES = "RECEIVE_RECENT_ENTRIES";
export const TOGGLE_NAV_PANEL = "TOGGLE_NAV_PANEL";
export const REQUEST_PINNED_EVENTS = "REQUEST_PINNED_EVENTS";
export const RECEIVE_PINNED_EVENTS = "RECEIVE_PINNED_EVENTS";
export const REQUEST_POPULAR_ENTRIES = "REQUEST_POPULAR_ENTRIES";
export const RECEIVE_POPULAR_ENTRIES = "RECEIVE_POPULAR_ENTRIES";
export const RECEIVE_MODERATOR = "RECEIVE_MODERATOR";
export const RECEIVE_ADMIN = "RECEIVE_ADMIN";
export const REQUEST_SUBMITTED_ENTRIES = "REQUEST_SUBMITTED_ENTRIES";
export const RECEIVE_SUBMITTED_ENTRIES = "RECEIVE_SUBMITTED_ENTRIES";
export const REQUEST_ALL_USERS = "REQUEST_ALL_USERS";
export const RECEIVE_ALL_USERS = "RECEIVE_ALL_USERS";
export const REQUEST_CHANGE_USER_TYPE = "REQUEST_CHANGE_USER_TYPE";
export const RECEIVE_CHANGE_USER_TYPE = "REVEIVE_CHANGE_USER_TYPE";
export const REQUEST_BAN_USER = "REQUEST_BAN_USER";
export const RECEIVE_BAN_USER = "RECEIVE_BAN_USER";
export const DELETE_USER = "DELETE_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const DELETE_OWN_ACCOUNT = "DELETE_OWN_ACCOUNT";
export const MOBILE_MENU_TOGGLE = "MOBILE_MENU_TOGGLE";
export const SERVER_CHECK = "SERVER_CHECK";
export const FORGOT_PASSWORD_CHANGE = "FORGOT_PASSWORD_CHANGE";
export const FORGOT_PASSWORD_SEND = "FORGOT_PASSWORD_SEND";